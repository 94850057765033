<template>
  <section class="banner h-auto mb-[100px]">
    <Nabvar />

    <!-- Banner de Contacto-->
    <div class="container pb-[34px] mx-auto px-4 h-auto">
      <div class="banner-container flex flex-wrap items-center">
        <div class="banner-text w-[600px]">
          <h1 class="font-roboto font-bold text-white font-[700] text-5xl">CONTÁCTANOS</h1><br>
          <p class="font-roboto font-light text-white font-[300] text-2xl text-justify">
            En Erax Elite, nos preocupamos por el éxito de nuestros creadores y ofrecemos una garantía para aquellos que
            cumplan con los requisitos mínimos y firmen un contrato.

            <br><br>

            Proporcionamos recursos y apoyo para ayudar a los creadores a alcanzar su máximo potencial y mejorar sus
            ganancias. Al compartir su información, nos das tu consentimiento para comunicarnos contigo, verificar tus
            datos y hacerte una oferta personalizada. En caso de que su solicitud no sea aprobada, agradecemos su
            interés y los alentamos a seguir mejorando para tener éxito en el futuro.
          </p>
        </div>
        <div class="banner-image w-[400px] m-auto">
          <img class="w-[326px] m-auto" src="../../public/img/logo_principal_contacto_erax.png" alt="">
        </div>
      </div>
    </div>
  </section>


  <!-- Sección form de contacto -->
  <section class="bg-section h-[1438px] py-8 bg-blue-100 relative">
    <div class="container mx-auto px-4 centered">
        <div class="form-container">
            <p class="font-roboto font-light text-[3em] font-[300] title-one">ME GUSTARÍA</p>
            <p class="font-roboto font-bold xl:text-5xl pb-[28px] title-two">
                SER PARTE DEL
                EQUIPO
            </p>
            <form @submit.prevent="enviarFormulario" action="sendContact" method="POST">


                <div class="relative z-0">
                  <input type="text" v-model="name" name="name" id="nombre"
                    class="block w-full  py-2 text-xl text-gray-900 bg-transparent border-0 border-b-2 border-gray-900 appearance-none dark:text-white dark:border-gray-900 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 h-12 peer"
                    placeholder=" " style="color: black;"/>
                  <label for="small_standard"
                    class="absolute text-1xl h-12 text-gray-900 dark:text-gray-900 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-10">Nombre</label>
                </div>
    
                <br>
    
                <div class="relative z-0">
                  <input type="text" v-model="email" name="email" id="email"
                    class="block w-full  py-2 text-xl text-gray-900 bg-transparent border-0 border-b-2 border-gray-900 appearance-none dark:text-white dark:border-gray-900 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 h-12 peer"
                    placeholder=" " style="color: black;"/>
                  <label for="small_standard"
                    class="absolute text-1xl h-12 text-gray-900 dark:text-gray-900 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-10">Correo
                    electrónico</label>
                </div>
    
                <br>
    
                <div class="relative z-0">
                  <input type="text" v-model="cel" name="cel" id="telefono"
                    class="block w-full  py-2 text-xl text-gray-900 bg-transparent border-0 border-b-2 border-gray-900 appearance-none dark:text-white dark:border-gray-900 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 h-12 peer"
                    placeholder=" " style="color: black;"/>
                  <label for="small_standard"
                    class="absolute text-1xl h-12 text-gray-900 dark:text-gray-900 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-10">Número
                    de teléfono</label>
                </div>
    
    
                <br>
    
                <div class="grid gap-4 grid-cols-2">
                  <div>
                    <i class="relative top-2 right-2  text-white fas fa-icono"> <img
                        src="../../public/img/icono_only_contacto_erax.svg"> </i> <input type="text" v-model="only"
                      name="only" id="only"
                      class="block w-full  py-2 text-xl text-gray-900 bg-transparent border-0 border-b-2 border-gray-900 appearance-none dark:text-white dark:border-gray-900 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 h-12 peer"
                      placeholder=" " style="color: black;"/>
    
                  </div>
                  <div>
                    <i class="relative top-2 right-2  text-white fas fa-icono"> <img
                        src="../../public/img/icono_insta_contacto_erax.svg"> </i> <input type="text" v-model="insta"
                      name="insta" id="insta"
                      class="block w-full  py-2 text-xl text-gray-900 bg-transparent border-0 border-b-2 border-gray-900 appearance-none dark:text-white dark:border-gray-900 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 h-12 peer"
                      placeholder=" " style="color: black;"/>
    
                  </div>
                  <div>
                    <i class="relative top-2 right-2  text-white fas fa-icono"> <img
                        src="../../public/img/icono_tik_contacto_erax.svg"> </i> <input v-model="tik" name="tik" type="text"
                      id="tik"
                      class="block w-full  py-2 text-xl text-gray-900 bg-transparent border-0 border-b-2 border-gray-900 appearance-none dark:text-white dark:border-gray-900 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 h-12 peer"
                      placeholder=" " style="color: black;"/>
                  </div>
    
                  <div>
                    <h1 class="relative top-2 right-2 text-black">Otro</h1> <input type="text" v-model="other" name="other"
                      id="other"
                      class="block w-full  py-2 text-xl text-gray-900 bg-transparent border-0 border-b-2 border-gray-900 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 h-12 peer"
                      placeholder=" " style="color: black;"/>
    
                  </div>
                </div>
                <br>
    
                <label for="small_standard" class="absolute text-1xl h-12 text-gray-900 dark:text-gray-900 duration-300 transform -translate-y-5">Estado de la cuenta</label>
                <select id="status" v-model="status" name="status" class="block py-5 px-0 w-full text-1xl text-gray-900 bg-transparent border-0 border-b-2 border-gray-900  dark:text-gray-900 dark:border-gray-900 focus:outline-none focus:ring-0 focus:border-gray-200 peer">
                  <option value="Nueva Cuenta">Nueva cuenta.</option>
                  <option value="Mi cuenta genera algunos ingresos">Mi cuenta genera algunos ingresos.</option>
                  <option value="Tengo buen rendimiento y busco un servicio completo">Tengo buen rendimiento y busco un servicio completo.</option>
                </select>
    
                <br>
    
    
                <!-- <input type="file" accept=".pdf" @change="onFileSelected" name="myfile"> -->
    
                <label for="exampleFormControlTextarea1"
                  class="pointer-events-none relative left-0 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-gray-900 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-gray-900 dark:peer-focus:text-primary">Envianos
                  un mensaje</label>
                <textarea v-model="tarea" name="tarea"
                  class="peer block min-h-[auto] w-full text-1xl text-gray-900 rounded border-2 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-gray-900 dark:placeholder:text-gray-900 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                  id="mensaje" rows="3" placeholder="Your message">
                </textarea>
    
                <br>
    
                <button type="submit" class="text-white bg-gradient-to-r btn  btn-block from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">
                  Enviar
                </button>
              </form>
        </div>
    </div>
</section>

<Footer class="fonde Footer-i" />

</template>

<style scoped>
/* Estilos banner informativo */
.banner {
  background-image: url("../../public/img/fondo_principal_contacto_erax.png");
}

.banner-text {
  margin-bottom: 0;
  /* Eliminar margen inferior si es necesario */
}

@media screen and (max-width: 1548px) {
  .banner-container {
    justify-content: center;
    text-align: justify;
  }

  .banner-image {
    padding-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 1280px) {
  .banner-image {
    margin-left: auto;
    margin-right: auto;
  }
}

/* Estilos form de contacto */

.bg-section {
  background-image: url('../../public/img/fondo_2_contacto_erax.png');
  background-size: cover;
  background-position: center;
}

.form-container {
  max-width: 468px;
  padding: 20px;
}
   
.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; 
} 

@media screen and (min-width: 951px) and (max-width: 1440px) {
.bg-section {
  height: 120vh;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
 .bg-section {
    height: 100vh;
    background-position: left;
  }
}

@media screen and (max-width: 844px) {
      .bg-section {
        background-position: -130vh 0vh;
        height: 870px;
      } 
    }

@media screen and (max-width: 767px) {
  .bg-section {
    height: auto;
    background-position: -188vh 0vh; /*160*/
  }
}

@media screen and (max-width: 400px) {
  .bg-section {
    height: auto;
    background-position: -190vh 0vh;
  }
}

/* Estilos form de contacto */


.footer {
  position: relative;
  top: 0px;
  text-align: center;
  align-items: center;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .footer {
    position: relative;
    top: 0px;
    text-align: center;
    align-items: center;
  }
}

/* media query ancho de la pantalla este entre 1280px y 1604px  */
@media (min-width: 1280px) and (max-width: 1604px) {
  .footer {
    height: 325px;
  }
}

</style>

<script>
import Swal from "sweetalert2";
import Nabvar from "../../src/components/Navbar.vue"
import Footer from "./Footer.vue";
import axios from 'axios';

export default {
  name: 'Contacto-i',
  components: {
    Nabvar,
    Footer
  },
  data() {
    return {
      action: 'sendContact',
      data: '',
      name: '',
      email: '',
      cel: '',
      only: '',
      insta: '',
      tik: '',
      other: '',
      status: '',
      tarea: '',
      selectedFiles: '',
    };
  },
  methods: {
    onFileSelected(event) {
      this.selectedFile = event.target.files[0]
    },
    enviarFormulario() {
      const nombre = this.name;
      const email = this.email;
      const mensaje = this.tarea;
      const telefono = this.cel;
      const only = this.only;
      const insta = this.insta;
      const tik = this.tik;
      const other = this.other;
      const status = this.status;

      // validacion del campo nombre
      if (!/^[A-Za-z\s]+$/.test(nombre)) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Por favor, ingresa un nombre válido (solo letras y espacios).',
          confirmButtonText: 'OK'
        });
        return;
      }

      // validacion del campo email
      if (!email || !/\S+@\S+\.\S+/.test(email)) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Por favor, ingresa una dirección de correo electrónico válida.',
          confirmButtonText: 'OK'
        });
        return;
      }

      // validacion del campo telefono
      if (!/^[0-9\s]+$/.test(telefono)) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Por favor, ingresa un número de teléfono válido (solo números y espacios).',
          confirmButtonText: 'OK'
        });
        return;
      }

      // validacion select estado cuenta 
      if (!status) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Por favor, selecciona un estado de cuenta.',
          confirmButtonText: 'OK'
        });
        return;
      } 

      // Si pasa todas las validaciones, enviar el formulario
      let formData = new FormData();
      formData.append('action', this.action);
      formData.append('nombre', nombre);
      formData.append('email', email);
      formData.append('mensaje', mensaje);
      formData.append('telefono', telefono);
      formData.append('only', only);
      formData.append('insta', insta);
      formData.append('tik', tik);
      formData.append('other', other);
      formData.append('status', status);

      try { // localhost/email.php
        axios.post('https://eraxelite.com/email.php', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then((response) => {
          console.log(response);
          if (response.data == true) {
            // Alerta de éxito
            Swal.fire({
              icon: 'success',
              title: '¡Enviado!',
              text: 'El formulario se ha enviado correctamente.',
              confirmButtonText: 'OK'
            }).then(() => {
              // Limpiar campos del formulario después de cerrar la alerta
              this.name = '';
              this.email = '';
              this.tarea = '';
              this.cel = '';
              this.only = '';
              this.insta = '';
              this.tik = '';
              this.other = '';
              this.status = '';
            });
          }
        });
      } catch (error) {
        console.error(error);
        // Alerta de error en caso de error en la solicitud
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'Hubo un error al enviar el formulario. Por favor, inténtalo de nuevo más tarde.',
          confirmButtonText: 'OK'
        });
      }
    },
  },
};
</script>
