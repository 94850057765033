<template>

<!-- Inicio primera imagen -->
  <div class=" fondo-principal absolute top-0  h-screen left-0 right-0  text-white p-4">
  <Nabvar/>
    
    <div class="container-message">
    <p class="dedit roboto" >Dedícate a</p>
    <p class="create">Crear</p>
    <p class="content">Contenido</p>
    </div>

    <p class="about">El resto lo hacemos nosotros</p>
    <a href="#"><img class="elite" src="../../public/img/logo_texto_principal_erax.png" alt=""></a>

    <div class="justify-end relative grid-cols-1 bottom-8 right-5">
    <p class="help">Te ayudamos a mejorar</p>
    <p class="help">tus ganancias y a gestionar</p>
    <p class="efi">tu cuenta de manera </p>
    <p class="efi">eficiente</p>
    <p class="saveit">Ahorrándote</p>
    <p class="saveit">Tiempo</p> 
    </div>
    
    <div class="iconos">
      <a href="tjt" class="icono-link"><i class="fas fa-icono text-white text-2xl m-2 p-4"><img class="w-14" src="../../public/img/icono_face_principal_erax.svg"></i></a>
      <a href="https://www.instagram.com/eraxelite/" class="icono-link"><i class="fas fa-icono text-white text-2xl p-4"><img class="w-14 relative left-5" src="../../public/img/icono_insta_principal_erax.svg"></i></a>
  </div>

  <a href="/contacto" class="buton ease-linear transform hover:scale-125 transition duration-500 content-center flex justify-center items-center  text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"> EMPEZAR YA!!!</a>
    
    
  </div> 
<!--Fin primera imagen -->


<!-- Inicio Segunda imagen -->
  <div   class=" fondo-principal-2 w-full bg-cover bg-center h-full" id="about">
  <div class="grid grid-cols-1 sm:grid-cols-3 absolute top-[-18rem]  sm:left-52 xl:top-[-12rem]  md:left-0 lg:left-28 lg:bottom-2/4 ">

    <!-- Primera columna -->
    <div class="col-span-1 p-4 relative  m-3 sm:left-40 top-60 md:relative md:left-0 lg:left-0">
      <img src="../../public/img/x_icono_pro_erax.png" alt="Imagen 1" class="w-30 h-auto relative sm:left-10 md:left-0  md:relative md:w-20 lg:w-30">
      <p class="mt-2 absolute text-1 text-6xl lg:text-6xl top-10 lg:left-36  left-32 md:left-24 md:text-4xl lg:top-5 roboto ">PRO</p>
      <h3 class="robotomedium  text-4xl absolute top-40 font-mono lg:text-4xl md:text-2xl " >Profesionalismo:</h3>
      <p class="robotolight text-2xl text-left relative top-10 lg:top-20 md:top-20 font-serif h-full w-60 " >
              Valoramos tu 
              confianza y estamos 
              comprometidos a 
              brindarte servicios 
              de alta calidad con 
              un enfoque profesional 
              en todas nuestras 
              interacciones contigo.
              Queremos que sientas
              que estás en buenas 
              manos
      </p>
    </div>

        <!-- Segunda columna -->
    
    <div class="col-span-1 p-4 relative m-3  sm:left-60 top-60 md:left-0  lg:left-14">
      <img src="../../public/img/x_icono_secret_erax.png" alt="Imagen 1" class="w-30 h-auto right-1/2 sm:left-10 md:left-0 md:w-20 lg:left-0 lg:w-30">
      <p class="mt-2 absolute text-1 text-6xl lg:text-6xl top-10 lg:left-36 left-32 md:left-24 md:text-4xl lg:top-5 roboto ">SECRET</p>
      <h3 class="robotomedium text-4xl absolute top-40 font-mono lg:text-4xl md:text-2xl  " >Confidencialidad:</h3>
      <p class="robotolight text-2xl text-left relative top-10 lg:top-20 md:top-20 font-serif h-full w-60 " >
        Tu privacidad y la
        seguridad de tus datos 
        son de suma importancia 
        para nosotros. Puedes 
        confiar en que mantenemos 
        la confidencialidad de tu 
        información
      </p>
    </div>

      <!-- Tercer columna -->
    <div class="col-span-1 p-4 relative  m-3 sm:left-60 top-60 md:left-0  lg:left-40">
      <img src="../../public/img/x_icono_only_erax.png" alt="Imagen 1" class="w-30 h-auto right-1/2 sm:left-10 md:left-0 md:w-20 lg:left-0 lg:w-30">
      <p class="mt-2 absolute text-1 text-6xl lg:text-6xl lg:left-36 top-10 left-32 md:left-24 md:text-4xl lg:top-5 roboto">ONLY</p>
      <h3 class="robotomedium  text-4xl absolute top-40 font-mono lg:text-4xl md:text-2xl " >Autenticidad:</h3>
      <p class="robotolight text-2xl text-left relative top-10 lg:top-20 md:top-20 font-serif h-full w-60 " >
        Promovemos la autenticidad
        en tu contenido y en nuestra
        relación contigo. Valorizamos
        la honestidad y la 
        transparencia en todas 
        nuestras interacciones
      </p>
    </div>

    

    


    
    
  </div>
  <!--Fin Segunda imagen -->






  </div>
<!--Fin Segunda imagen -->


<!-- Inicio Tercer imagen -->
<div id="ask" class="grid grid-cols-1 xl:relative top-20 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 fondo-principal-3">
  <!-- Primera columna de texto -->
  <div class="col-span-1 bg-blue-950 relative h-40">
  <p class="text-white relative top-8 xl:left-44 md:left-1 text-left left-20 w-72 text-4xl roboto texto-1">Tú dedícate a crear contenido.</p>
  </div>

  <!-- Columna central con imagen -->
  <div class="col-span-1 p-0 bg-gradient-to-r from-cyan-500 to-blue-500 focus:ring-cyan-300 dark:focus:ring-cyan-800">
  <img src="../../public/img/logo_icono_banner_erax.png" alt="Imagen" class="logo-erax relative w-32 left-28 m-2 xl:w-28	xl:h-auto  xl:relative xl:left-52 xl:top-2 md:w-28 md:left-20 md:top-6 ">
  </div>

  <!-- Tercera columna de texto -->
  <div class="col-span-1 bg-white flex items-center justify-center h-full">
  <p class="text-indigo-600 text-center xl:text-center relative xl:top-5 top-4 italic xl:left-2 xl:w-90 text-4xl roboto">
    NOSOTROS HACEMOS EL RESTO.
  </p>
</div>


  </div>
<!--Fin Tercer imagen -->



<!-- inicio cuarto imagen -->

<div   class="section grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-2  lg:grid-cols-2 relative top-32 place-items-center h-auto fondo-principal-4">
  

  <!-- Primera columna -->
  <div class="col-span-1 mb-6 relative right-60 bottom-96 lg:left-20 md:bottom-80 md:right-70 xl:relative xl:top-[46px] xl:left-10 column">
  
    <p class="relative text-5xl left-20	 md:left-80 md:bottom-6  xl:text-6xl xl:bottom-10  ahorra">Ahórrate tiempo!!!</p>
    <p class="ahorra2 relative  text-4xl  md:relative md:bottom-8 md:text-3xl xl:bottom-8 xl:text-left xl:text-4xl" >Y mejorar tus ganancias con nuestros beneficios</p>
      <br> 
    <!-- Imagen -->
    <img src="../../public/img/logo_icono_vertical_erax.png " alt="Imagen" class="relative  w-64 h-64  left-80 bottom-24  md:bottom-10 md:left-3/4  md:w-80 md:h-80  xl:bottom-10  xl:left-72 xl:w-1/3 xl:h-1/3 mt-4">
    
    <!-- Tarjeta 1 -->
    <div class="max-sm:flex justify-center items-center bg-white relative left-60 mb-[50px]">
    <div class="block max-w-[24rem] xl:max-w-[24rem] rounded-lg border border-success bg-transparent shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:border-success-300 dark:bg-neutral-600"> 
      <div class="relative  p-6 mr-18  bg-indigo-500">
      <i class="absolute top-2 right-3  text-white fas fa-icono"> <img class="w-12" src="../../public/img/icono_estadisticas_beneficios_erax.svg"> </i>
      <br>
      
      <i class="absolute top-2 left-2   text-white fas fa-icono"> <img class=" w-16  relative top-5 left-1 "  src="../../public/img/icono-estrella.png"> </i>
      <br><br>
      
      <h5  class="mb-2 text-3xl text-sky-400 font-medium leading-tight text-success dark:text-success-300 roboto" >RESULTADOS</h5>
      <small class="text-2xl text-sky-400 robotomedium " >Medibles</small><br> 
      <p class="text-xl w-80s  text-success text-white  dark:text-success-300 robotolight">
        Nos destacamos por ofrecer resultados 
        desde el primer mes de colaboración.</p>
         <br>
         <p class=" text-xl w-80s  text-success text-white  dark:text-success-300 robotomedium" > Además, proporciona reportes semanales y mensuales
        para que puedas medir el progreso y ajustar las estrategias.</p>
      </div>
    </div>                    
    </div>
  </div>

    
 




  <!-- Segunda columna -->
  <div class="col-span-1 relative bottom-80 lg:relative lg:left-18 lg:top-12 md:bottom-72 xl:relative  xl:top-10  ">

    <!-- Tarjeta 3 -->
    <div class="bg-white">

      
      <div class="bg-white bottom-12  p-4 mt-4 relative ">
    <div class="block max-w-[24rem] rounded-lg border border-success bg-transparent shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:border-success-600 dark:bg-blue-700">
 
      <div class="relative p-6  bg-white">
      <i class="absolute top-2 right-2  text-white fas fa-icono" style="font-size: 2rem;">  <img class="w-12" src="../../public/img/icono_propina_beneficios_erax.svg"> </i>

      <i class="absolute top-2 left-4   text-white fas fa-icono"> <img class=" w-8  relative top-2 left-3 "  src="../../public/img/icono-pesos.png"> </i>
      <br><br>
    
      <small class="text-3xl text-indigo-500  robotomedium " >Ganancias</small> <br> 
      <h5  class="mb-2 text-2xl text-indigo-500 font-medium leading-tight text-success dark:text-success-300  roboto" >POR PROPINA</h5>
      <p class="text-xl text-success text-black w-70 dark:text-success-300  robotomedium">
        Te ayudamos a cerrar ventas e 
        incrementar tus ganancias en esta
        sección
      </p>
      </div>
    </div>
    </div>



      <!-- Contenido de la tarjeta -->
    </div>

    <!-- Tarjeta 4 -->
    <div class="bg-white">


       <div class="bg-white  p-4 mt-4 relative ">
    <div class="block max-w-[24rem] relative bottom-20 my-2  rounded-lg border border-success bg-transparent shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:border-success-300 dark:bg-neutral-600">
 
      <div class="relative p-14  bg-blue-700">
      <i class="absolute top-2 right-2  text-white fas fa-icono" style="font-size: 2rem;">  <img   class="w-12" src="../../public/img/icono_csubs_beneficios_erax.svg"> </i>
    
      <i class="absolute top-2 left-2   text-white fas fa-icono"> <img class=" w-16  relative top-2 left-3 "  src="../../public/img/icono-iman.png"> </i>
      <br><br>

      <h5  class="text-3xl text-white  leading-tight text-success dark:text-success-300 relative xl:right-10 roboto" >RETENCIÓN</h5>
      
      <small class="text-2xl text-white relative xl:right-10 robotomedium" >De Suscriptores</small> <br>
      <p class="text-lg xl:w-80 text-success text-white w-60 dark:text-success-300 relative xl:right-10 robotomedium">
        Contamos con diferentes estrategias 
        para retener a tus fans y mantenerlos 
        felices todo el tiempo.
      </p>
      </div>
    </div>
    </div>
      <!-- Contenido de la tarjeta -->
    </div>
  </div>
</div>
<!-- fin cuarto imagen -->


<!-- inicio quinto imagen -->
<div id="inf" class="absolute fondo-principal-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-1 gap-8 ">
  <!-- Imagen de fondo -->
  <div class=" bg-center  lg:h-20 xl:bg-cover ">
    <img src="../../public/img/fondo_3_inicio_erax.png" alt="" class="xl:w-full xl:h-auto	 h-screen grid-content-center ">
  </div>

  <!-- Segunda imagen superpuesta -->
  <img src="../../public/img/foto_bannerfinal_modelo_erax.png " alt="Imagen Superpuesta" class="textin imgx absolute top-10  md:top-4 md:left-10 lg:absolute lg:top-10 lg:w-2/2   xl:w-2/1 xl:top-[-2rem] xl:left-1/3 ">

   <!-- Texto en la parte superior izquierda -->
  <p class="text-sky-400 sup-izq1 text-2xl p-4 absolute top-0 md:text-5xl md:absolute md:left-16 md:top-20 xl:text-6xl xl:absolute xl:left-[17rem] xl:top-36 roboto">+18</p>
   <p class="text-sky-400 sup-izq  text-xl p-4 w-10 absolute top-14  md:text-3xl md:absolute md:w-40 md:left-10 md:top-40 xl:w-1/4	 xl:absolute  xl:left-72 xl:top-52 xl:text-3xl" >Trabajamos únicamente con mayores de edad.</p> 

  <!-- Texto en la parte inferior izquierda -->
   <p class="  text-sky-400 inf-izq ">Solo envía tu 
      contenido e 
      inmediatamente
      generas nuevos
      ingresos.
</p>


 <p class=" text-sky-400 inf-izq1">Rápido, fácil y seguro.</p>  

  <!-- Icono en la parte superior derecha -->

  <a href=""><i class="fas fa-icono text-white text-2xl absolute right-16 top-24 md:absolute md:right-52 md:top-24 xl:top-28 xl:right-64 p-4"><img class=" w-12 md:w-12 xl:w-16" src="../../public/img/icono_face_principal_erax.svg"> </i></a>
    <a href="https://www.instagram.com/eraxelite/"><i class="fas fa-icono text-white text-2xl absolute right-2 top-24 md:absolute md:right-36 md:top-24 xl:top-28 xl:right-40 p-4"><img class="w-12 md:w-12 xl:w-16 " src="../../public/img/icono_insta_principal_erax.svg "> </i></a>

 
  <!-- Imagen en la parte inferior derecha -->

  <img src="../../public/img/logo_texto_principal_erax.png" alt="Imagen" class="lis-r rotate-90	 down">


 
</div>
<!-- fin quinto imagen -->

<Footer />
    
</template>

<style scoped>

.robotolight{
  font-family: 'Roboto Light', sans-serif;

}

.robotomedium{
  font-family: 'Roboto Medium', sans-serif;

}

.roboto{
font-family: 'Roboto', sans-serif;
font-weight: bold;

}


.imgx {
    position: absolute;
    top: 24px;
    width: 700px;
  }


@media (min-width: 768px) and (max-width: 1366px) {
  .imgx {
    position: absolute;
    top: -24px;
    width: 520px;
  }
}


@media (min-width: 780px) and (max-width: 1729px) {

 .imgx {
    position: absolute;
    top: 24px;
    width: 500px;
  }
}




.fonde{
position: absolute;
background: #60E7FD;
top: 4007px; /* 200px */
font-family: futura;
height: 34%; /* 260px */
width: 100%;
}

@media (min-width: 780px) and (max-width: 1729px) {

.fonde{
position: absolute;
background: #60E7FD;
top: 3605px; /* 325px, 3859px, 3734px */
height: 300px; 
font-family: futura;

}

}



@media (min-width: 768px) and (max-width: 1366px) {
  .fonde{
position: absolute;
background: #60E7FD;
top: 3250px; /* 1250px */
height: 300px;

font-family: futura;

}
}


@media (max-width: 767px){
  .fonde{
position: absolute;
background: #60E7FD;
top: 6087px; /* -30px */
/* height: 200px; */
font-family: futura;

}
}


.down{

  position: absolute;
  top: 510px;
  left: 1400px;
  width: 18%;
}


@media (min-width: 768px) and (max-width: 1366px) {
  .down{

position: absolute;
top: 540px;
left: 980px;
width: 18%;

}
}




@media (max-width: 767px){
.down{
position: absolute;
top: 600px;
left: 230px;
width: 38%;
}
}




.inf-izq{
  position: relative;
  left: 118px;
  font-size: 32px;
  top: 110px;
  width: 37%;
  font-family: 'Roboto Medium', sans-serif;
}


@media (min-width: 768px) and (max-width: 1366px) {
  .inf-izq{
  position: relative;
  left: 100px;
  font-size: 32px;
  top: 120px;
  width: 40%;
  font-family: 'Roboto Medium', sans-serif;
}
}


@media (min-width: 768px) and (max-width: 1024px) {
  .inf-izq{
  position: relative;
  left: 42px;
  font-size: 32px;
  top: 350px;
  width: 60%;
  font-family: 'Roboto Medium', sans-serif;


}
}

@media (max-width: 767px){
  .inf-izq{
  position: absolute;
  left: 15px;
  font-size: 22px;
  top: 420px;
  width: 38%;
  font-family: 'Roboto Medium', sans-serif;


}
}


.inf-izq1{
font-family: 'Roboto Medium', sans-serif;
position: relative;
top: 220px;
left: 130px;
font-size: 32px;
width: 15%;
font-weight: 700;
}


@media (min-width: 768px) and (max-width: 1366px) {
  .inf-izq1{
  position: relative;
  left: 100px;
  font-size: 32px;
  top: 160px;
  width: 18%;
  font-family: 'Roboto Medium', sans-serif;
}
}

@media (min-width: 768px) and (max-width: 1024px) {
  .inf-izq1{
font-family: 'Roboto Medium', sans-serif;
position: relative;
top: 360px;
left: 40px;
font-size: 32px;
width: 30%;
font-weight: 700;
}
}





.sup-izq1{
    position: absolute;
    top: 60px;
    left: 70px;
    font-family: futura;
   
  }


  @media (min-width: 768px) and (max-width: 1024px) {
.sup-izq1{
    position: absolute;
    top: 60px;
    left: 160px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
   
  }

}


@media (min-width: 768px) and (max-width: 1366px) {
  .sup-izq1{
    position: absolute;
    top: 60px;
    left: 80px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
   
  }
}

@media (max-width: 767px){
 .sup-izq1{
    position: absolute;
    top: 20px;
    left: -6px;
   font-family: 'Roboto', sans-serif;
    font-weight: bold;
   
  }
}






@media (max-width: 767px){
.inf-izq1{
font-family: 'Roboto Medium', sans-serif;
position: absolute;
top: 650px;
left: 18px;
font-size: 20px;
font-weight: 700;
width: 20%;



}
}



.sup-izq{
  font-family: 'Roboto Medium', sans-serif;
  position: absolute;
  left: 80px;
  top: 130px;
   width: 30%;
} 


@media (min-width: 780px) and (max-width: 1729px) {
 .sup-izq{
    position: absolute;
    top: 130px;
    left: 100px;
    width: 30%;
    font-family: 'Roboto Medium', sans-serif;
   
  }

}

@media (min-width: 768px) and (max-width: 1366px) {
  .sup-izq{
    position: absolute;
    top: 130px;
    left: 90px;
    width: 37%;
    font-family: 'Roboto Medium', sans-serif;
   
  }
}

@media (max-width: 767px){
.sup-izq{
  font-family: 'Roboto Medium', sans-serif;
  position: absolute;
  left: -5px;
  top: 50px;
   width: 42%;
} 

}








.fondo-principal-5{

  position:relative;
  top: 2000px;
  height: 100vh;
  overflow: hidden;
}

@media (min-width: 780px) and (max-width: 1729px) {

.fondo-principal-5{

  position:relative;
  top: 1700px;
  height: 100vh;
}
}


@media (min-width: 768px) and (max-width: 1366px) {
  .fondo-principal-5{

position:relative;
top: 1260px;
height: 100vh;
}

}


@media (min-width: 768px) and (max-width: 1024px) {
.fondo-principal-5{
  position:relative;
  top: 1800px;
 height: 100vh;
}
}

@media (max-width: 767px){
.fondo-principal-5{

position:relative;
top: 3400px;
height: 100vh;
}
}

@media (max-width: 767px){
  .cel{
  display: none;
}
}


.ahorra2{
  position: relative;
  font-family: 'Roboto Medium', sans-serif;
  width: 60%;
  
  left: 280px;

}

@media (min-width: 768px) and (max-width: 1024px) {
  .ahorra2{
  position: relative;
  left: 380px;
  bottom: 6px;
  font-family: 'Roboto Medium', sans-serif;
  width: 56%;

}
}


@media (max-width: 767px){
  .ahorra2{
  position: relative;
  left: 350px;
  bottom: 100px;
  font-family: 'Roboto Medium', sans-serif;
  align-items: center; 
  width: 6%;
}
}




.ahorra{
  position: relative;
  left: 280px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;

  width: 10%;
} 

@media (min-width: 768px) and (max-width: 1024px) {
  .ahorra{
  position: relative;
  left: 380px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-weight: bold;
  width: 10%;
}
}


@media (max-width: 767px){
  .ahorra{
  position: relative;
  left: 350px;
  bottom: 100px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  align-items: center; 
  font-weight: bold;
  width: 10%;
}
}


.fondo-principal-4 {
 
  position:relative;
  top: 2000px;
  margin-bottom: 4%;
  /* background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */
  
}

@media (min-width: 780px) and (max-width: 1729px) {
.fondo-principal-4 {
 
  position:relative;
  top: 1700px;
  /* background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */
  
}
}


@media (min-width: 768px) and (max-width: 1366px) {
  .fondo-principal-4 {
 
 position:relative;
 top: 1300px;
 left: -100px;
 /* background-size: cover;
 background-position: center;
 background-repeat: no-repeat; */
 
}

}

@media (max-width: 767px){
  .fondo-principal-4 {
 
 position:relative;
 top: 3800px;
 /* background-size: cover;
 background-position: center;
 background-repeat: no-repeat; */
 
}

}

@media screen and (max-width: 1440px) and (min-width: 900px) {
  .column {
    margin-top: 66px;
  }
}


.fondo-principal-3 {
 
  position:relative;
  top: 1905px; /* 2000px */
  /* background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */
  
}


@media (min-width: 780px) and (max-width: 1729px) {

.fondo-principal-3 {
 
  position:relative;
  top: 1730px;
  /* background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */
  
}
}


@media (min-width: 768px) and (max-width: 1366px) {
  .fondo-principal-3 {
 
 position:relative;
 top: 1335px;
 
 
}
}




@media (min-width: 768px) and (max-width: 1024px) {
  .fondo-principal-3 {
 
 position:relative;
 top: 1620px;
 /* background-size: cover;
 background-position: center;
 background-repeat: no-repeat; */
 
}
}

@media (max-width: 767px){
  .fondo-principal-3 {
 
 position: absolute;
 top: 2830px;
 /* background-size: cover;
 background-position: center;
 background-repeat: no-repeat; */
 
}

}

.fondo-principal-2 {
  background-image: url('../../public/img/fondo_2_inicio_erax.png');
  position:absolute;
  top: 951px; /*1048px*/
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  
}


@media (min-width: 780px) and (max-width: 1729px) {

.fondo-principal-2 {
  background-image: url('../../public/img/fondo_2_inicio_erax.png');
  position:absolute;
  top: 899px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  
}
}


@media (min-width: 768px) and (max-width: 1366px) {
  .fondo-principal-2 {
 
  background-image: url('../../public/img/fondo_2_inicio_erax.png');
  position:absolute;
  top: 720px;
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
 
}
}


@media (min-width: 768px) and (max-width: 1024px) {
  .fondo-principal-2 {
 
 
 position:relative;
 top: 800px;
 
 /* background-size: cover;
 background-position: center;
 background-repeat: no-repeat; */
 
}
}

@media (max-width: 767px){
  .fondo-principal-2 {
 
 
 position:absolute;
 top: 1340px;
 /* background-size: cover;
 background-position: center;
 background-repeat: no-repeat; */
 
}
}

.fondo-principal {
  background-image: url('../../public/img/fondonew.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}


/* @media (min-width: 768px) and (max-width: 1366px) {

.fondo-principal {
  background-image: url('../../public/img/fondonew.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 
  height: 130vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
} */


@media (min-width: 780px) and (max-width: 1729px) {

.fondo-principal {
  background-image: url('../../public/img/fondonew.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 
  height: 115vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
}


@media (max-width: 767px){

.fondo-principal {
  background-image: url('../../public/img/fondonew.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 
  height: 145vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
}



@media only screen and (max-width: 390px) {
  .container-message {
    margin-bottom: auto;
  }

  .dedit {
    top: 300px;
    left: 1px;
  }

  .create {
    margin-top: 55px;
    left: -66px;
  }

  .content {
    margin-top: 85px;
    left: 1px;
  }
}



@media screen and (min-width: 391px) and (max-width: 639px) {
  .container-message {
    margin-bottom: auto;
  }

  .dedit {
    top: 300px;
    left: 1px;
  }

  .create {
    margin-top: 55px;
    left: -66px;
  }

  .content {
    margin-top: 85px;
    left: 1px;
  }

  #ask {
    width: 100%;
  }

  .texto-1 {
    text-align: center;
  }
  
  .logo-erax {
    margin-left: 30px;
  }
}


.dedit{
position: relative;
top:90px;
right: 410px;
font-size: 62px;
font-family: 'Roboto Light', sans-serif;
color: whitesmoke;

}

@media (min-width: 768px) and (max-width: 1023px) {
.dedit{
position: relative;
right: 265px;
bottom: -48px;
font-size: 48px;
font-family: 'Roboto Light', sans-serif;

}
}

@media (max-width: 767px) {
.dedit{
position: relative;
right: 80px;
top: 270px;
font-size: 40px;
font-family: 'Roboto Light', sans-serif;

}
}



.create{

position: relative;
right: 478px;
font-size: 62px;
top: 51px;
margin-left: 66px;
font-family: 'Roboto Italic', sans-serif;

}

@media (min-width: 768px) and (max-width: 1024px) {
.create{
position: relative;
right: 315px;
top: 25px;
font-size: 48px;
font-family: 'Roboto Italic', sans-serif;

}
}

@media (max-width: 767px) {
.create{
position: relative;
right: 130px;
top: 197px;
font-size: 36px;
font-family: 'Roboto Italic', sans-serif;

}
}

.content{

position: relative;
right: 410px;
top: 10px;
font-size: 62px;
font-family: 'Roboto Medium', sans-serif;


}

@media (min-width: 768px) and (max-width: 1024px) {
.content{
position: relative;
right: 269px;
top: -30px;
font-size: 48px;
font-family: 'Roboto Medium', sans-serif;


}
}

@media (max-width: 767px) {
.content{
position: relative;
right: 83px;
top: 90px;
font-size: 40px;
font-family: 'Roboto Medium', sans-serif;


}
}

.textin{
  font-family: futura;

}

.about{

position: relative;
right: 380px;
top: 40px;
font-size: 28px;
font-family: 'Roboto Medium', sans-serif;
color: aqua;

}

@media (min-width: 768px) and (max-width: 1024px) {
.about{
position: relative;
right: 240px;
top: 90px;
font-size: 20px;
font-family: 'Roboto Medium', sans-serif;
color: aqua;

}
}

@media (max-width: 767px) {
.about{
position: relative;
right: -2px;
top: -500px;
font-size: 24px;
font-family: 'Roboto Medium', sans-serif;

}
}


/* @media (min-width: 1024px) and (max-width: 1279px) {
  .help{
  position: relative;
  bottom: 400px;
  left: 128px;
  font-family: monospace;
  font-size: 28px;
  margin-right: 10px;
}

} */

.help{
  position: relative;
  bottom: 340px;
  left: 478px;
  font-family: 'Roboto Light', sans-serif;;
  text-align: right;
  font-size: 28px;
  
}


@media (min-width: 768px) and (max-width: 1024px) {
  .help{
  position: relative;
  bottom: 440px;
  left: 270px;
  font-family: 'Roboto Light', sans-serif;;

  font-size: 21px;
  
}

}

@media (max-width: 767px) {
  .help{
 
  display: none;
}

}


.efi{
position: relative;
bottom: 330px;
left: 470px;
font-family: 'Roboto Medium', sans-serif;
font-size: 25px;
text-align: right;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .efi{
position: relative;
bottom: 420px;
left: 273px;
font-family: 'Roboto Medium', sans-serif;
font-size: 24px;
}

}

@media (max-width: 767px) {
  .efi{

display: none;
}

}


.saveit{
position: relative;
bottom: 250px;
left: 480px;
font-family: 'Roboto', sans-serif;
font-weight: bold;
font-size: 34px;
text-align: right;


}

@media (min-width: 768px) and (max-width: 1024px) {
.saveit{
font-weight: bold;
position: relative;
bottom: 380px;
left: 323px;
font-family: futura;
font-size: 24px;
}

}

@media (max-width: 767px) {
.saveit{

display: none;
}

}



.elite{
position: absolute;
left: 300px;
top: 780px;
margin-right: 10px; 
}



@media (min-width: 768px) and (max-width: 1366px) {
 
.elite{
position: absolute;
left: 110px;
top: 620px;
margin-right: 10px; 
}
}




@media (max-width: 767px) {

  .elite{
position: absolute;
left: 10%;
top: 840px;

 
}

}




.iconos {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -15px;
  left: 420px;
  
}


@media screen and (max-width: 1440px) and (min-width: 900px) {
  .iconos {
    top: -90px;
  }
}

@media (min-width: 768px) and (max-width: 1366px) {
  .iconos {
    position: absolute;
    top: 600px;
    left: 81%;
    transform: translateX(-50%);
    width: 100px;
    justify-content: space-around; /* Ajusta según tus necesidades */
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .iconos {
    position: absolute;
    top: 850px;
    left: 78%;
    transform: translateX(-50%);
    width: 100px;
    justify-content: space-around; /* Ajusta según tus necesidades */
  }
}

@media (max-width: 767px) {
  .iconos {
    position: absolute;
    top: 970px;
    left: 35%;
    
    
    
  }
}



.buton{
position: relative;
top: -240px;
left: 30px;
width: 14%;
}


@media (min-width: 768px) and (max-width: 1024px) {

  .buton{
position: relative;
top: -180px;
left: 40px;
width: 42%;
}


}

@media (max-width: 767px) {

  .buton{
position: absolute;
top: 920px;
left: 25%;
width: 50%;
}
}


@media (max-width: 767px) {
  /**/
}


</style>



<script>
import Footer from "./Footer.vue";
import Nabvar from "../../src/components/Navbar.vue"

export default {
  name: 'HelloWorld-i',
  components:{
    Footer,
    Nabvar, 
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

