<template>


 <!-- inicio primera imagen -->
 <div class="relative  ">
  <!-- <img src="../../public/img/fondo_principal_inicio_erax.png" alt="" class="w-full h-auto im1"> -->
  <ul class="menu  relative  top-0 left-0 right-0  flex justify-center items-center list-none ">
    <li><a href="#inf">¿QUÉ SOMOS?</a></li>
    <li><a href="#about">¿QUÉ HACEMOS?</a></li>
    <li><router-link to="/"> <img src="../../public/img/logo_icono_menu_erax.png" alt="" ></router-link></li>
    <li><a href="#ask"  >¿TIENES DUDAS?</a></li>
    <li><router-link to="/contacto">CONTACTO</router-link></li>

  </ul>

  
  </div>
  <!-- fin primera imagen -->

    
</template>


<style scoped >



.menu {
/* background-image: url("../../public/img/fondo_principal_contacto_erax.png "); */
  list-style-type: none;
  display: flex;
  padding: 1em;
  font-size: 0px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
  .menu {
  list-style-type: none;
  display: flex;
  

}
}



@media (max-width: 767px) {
  .menu {
/* background-image: url("../../public/img/fondo_principal_contacto_erax.png "); */
  list-style-type: none;
  display: flex;

  
  }
}





/* ============================================================================================================================================================================================================================================================================ */

.menu li {
  font-size: 0px; /* Cambia el tamaño de fuente según tus preferencias */
  margin: 20px;
}

@media (max-width: 767px) {
.menu li {
font-size: 10px; /* Cambia el tamaño de fuente según tus preferencias */

}
}


@media (min-width: 769px) and (max-width: 1024px) {
  .menu li {
font-size: 0px; /* Cambia el tamaño de fuente según tus preferencias */
}
}
/* ============================================================================================================================================================================================================================================================================ */


.menu a {
  
  color: white;
  font-weight: bold;
  
  font-size: 21px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .menu a {
  color: white;
  font-size: 13px;
  
}
}

@media  (max-width: 767px) {
  .menu a {
  
  color: white;
  font-weight: bold;

  font-size: 10px;
 
}
}

/* ============================================================================================================================================================================================================================================================================ */

.menu img {
  width: 100%; /* Ajusta el tamaño de la imagen según tus necesidades */
}


@media (min-width: 768px) and (max-width: 1024px) {
    .menu img {
  width: 100%; /* Ajusta el tamaño de la imagen según tus necesidades */
}
}


@media (max-width: 767px) {
  .menu img {
  width: 100%; /* Ajusta el tamaño de la imagen según tus necesidades */
}
}
</style>

<script>
import $ from 'jquery';



$("a[href^='#']").click(function(e) {
	e.preventDefault();
	
	var position = $($(this).attr("href")).offset().top;

	$("body, html").animate({
		scrollTop: position
	} /* speed */ );
});

export default {
    name: 'Navbar-i',
    
    
    }

</script>
