<template>
  
 

  <router-view></router-view>

</template>

<script>





export default {
  name: 'App',
  
 
}
console.log('KZ the Kaiser =D')
</script>

<style>



</style>
