<template>

<div class="fonde">
  <div class="grid grid-cols-2 ">
    <!-- Columna de texto (lado izquierdo) -->
    <div class="col-span-1">
      
      
      <!-- Columna de imagen (lado derecho) -->
      <div class="relative left-10 md:left-32 xl:left-1/2 pt-3">
        <!-- Contenedor principal -->
        <div class="relative top-4 p-0 m-0 text-left w-[60%] text-justify">         
          <p class="font-roboto font-light text-white text-3xl  md:text-5xl xl:text-7xl enf ">Dedícate a</p> 
          <p class="font-roboto text-white text-2xl md:text-4xl xl:text-6xl pos " style="font-style: italic;">Crear</p>
          <p class="font-roboto font-bold text-white text-2xl  md:text-4xl xl:text-6xl  haz">Contenido</p>
          <small class="font-roboto-medium text-purple-700 text-1xl md:text-2xl xl:text-4xl xl:relative xl:right-0">El resto lo hacemos nosotros.</small>
        </div>
 
        <!-- Imagen en la parte inferior derecha -->
        <div class="absolute right-0 md:right-0 xl:right-1 bg-green-100 h-0">
          <img src="../../public/img/logo_2_footer_erax.png " alt="Imagen" class=" relative left-32 bottom-28 w-40 md:relative  md:bottom-28  md:left-14  md:w-40 xl:relative xl:bottom-56 xl:w-full xl:h-auto">
        </div>
      </div>
    </div>
  </div>
</div>
       
</template>

<script>

export default {
  name: 'Footer-i',
  
};
</script>


<style scoped>

.fonde{
  position: relative;
  background: #60E7FD;
  /* top: 380px; */
  height: 320px; /*280px*/
} 


@media (min-width: 768px) and (max-width: 1024px) {
  .fonde {
    position: relative;
  }
}

@media (max-width: 767px) {
  .fonde{
    position: relative;
  }
} 
    
</style>
