import { createApp } from 'vue'
import App from './App.vue'
import './style.css'
import router from './router'; // Importa el archivo de ruta

// import Contacto from './components/Contacto.vue'

// App.component('Contacto', Contacto);




const app = createApp(App);


app.use(router)

app.mount('#app')
