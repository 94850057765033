import { createRouter, createWebHistory } from 'vue-router';
import Contacto from '../src/components/Contacto.vue';


import HelloWorld from '@/components/HelloWorld.vue'


const routes = [
  {
    path: '/contacto',
    name: 'Contacto-i',
    component: Contacto
  },
 {
  path: '/',
  name: 'HelloWorld',
  component: HelloWorld
 },
 
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})



export default router;
